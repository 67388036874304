<template>
    <el-card>
        <div slot="header" class="main-page-title"><span>首页</span></div>

        <el-row :gutter="20">



        </el-row>
        <br>





    </el-card>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

        created() {
        },
        methods: {


        }
    }
</script>
