<template>
    <div id="app">
        <el-container>
            <el-aside class="el-aside" width="160px">
                <div style="text-align: center">
                    <img width="50%" src="https://files.aqsctzzy.com/common/logo.png" alt="">
                </div>
                <br>
                <el-menu @select="selectMenu">
                    <el-menu-item :index="menu.router" :key="menu.id" v-for="menu in menuData">
                        <i class="el-icon-menu"></i>
                        {{menu.name}}
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header class="el-header">
                    <div style="font-size: 25px;font-weight: 800">后台管理系统</div>
                </el-header>
                <el-main class="el-main">
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<style>
    .el-header {
        /*background-color: #5a9ef8;*/
        color: #333;
        line-height: 60px;

        /*box-shadow:12px 2px 2px 0 rgba(0, 0, 0, 0.1);*/
        /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)*/
    }

    .el-aside {
        color: #333;
    }

    .el-main {
        min-height: 500px;
        background-color: #eee;
        padding: 10px;
        /*border: 10px solid #eee*/
    }
    .el-menu-item{
        height: 40px;
        line-height: 30px;
    }
</style>


<script>
    export default {
        data() {
            return {
                menuData: [],
                activeMenu: '',
            }
        },
        created() {
            this.getDataList();
        },
        watch: {// 路由控制菜单选中状态
            '$route'(to) {
                // console.log(to);
                this.activeMenu = to.name;
            }
        },
        methods: {
            selectMenu(index) {
                console.log("ok")
                this.$router.push(index)
            },
            getDataList() {
                this.menuData = [];
                this.$http.get("/backend/menus").then(res => {
                    console.log(res.data);
                    this.menuData = res.data.data;
                });
            },

        }
    }
</script>
