<template>
    <el-card>
        <div slot="header" class="main-page-title"><span>学习记录</span></div>
        <el-row :gutter="20">
            <el-col :span="6">
                <el-input v-model="searchValue" size="small" placeholder="请输入内容"></el-input>
            </el-col>
            <el-col :span="3">
                <el-button type="primary" icon="el-icon-search" size="small" circle @click="getDataList"></el-button>
            </el-col>
        </el-row>
        <br>
        <el-row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">
                <el-table-column prop="user" label="用户" width="125">
                    <template slot-scope="scope">
                        {{ scope.row.user.nickname }}(ID:{{ scope.row.user.id }})
                    </template>
                </el-table-column>
                <el-table-column prop="subject.name" label="学习科目"></el-table-column>
                <el-table-column prop="learn_type" label="学习类型"></el-table-column>
                <el-table-column prop="pattern" :formatter="tableFormatPattern" label="学习模式"></el-table-column>
                <el-table-column prop="start_time" label="开始时间"></el-table-column>
                <el-table-column prop="end_time" label="结束时间"></el-table-column>
                <el-table-column prop="score" label="考试成绩"></el-table-column>
            </el-table>
        </el-row>

        <el-row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </el-row>


    </el-card>
</template>

<script>
export default {
    data() {
        return {
            nowPage: 1, // 当前页
            pageTotal: 0, // 总页数
            pageSize: 10, // 每页数量

            tableData: [],

            searchValue: '', // 搜索值
            searchType: '', // 搜索值
            searchSubjectId: '',

            oneItem: {},
            editModel: false, // 新增编辑
            detailsModel: false, // 详情弹窗

            importTopicModel: false,
        }
    },

    created() {
        this.getDataList();
    },
    methods: {
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getDataList()
        },
        handlePageChange(page) {
            this.nowPage = page;
            this.getDataList()
        },
        getDataList() {
            this.tableData = [];
            this.$http.get("/backend/learns", {
                params: {
                    page: this.nowPage,
                    pre_page: this.pageSize,
                    word: this.searchValue,
                }
            }).then(res => {
                console.log(res.data);
                this.tableData = res.data.data;
                this.pageTotal = res.data.meta.total;
            });
        },


        openAddItemModel() {
            this.oneItem = {
                name: '',
                pid: 0,
            };
            this.editModel = true;
        },
        openEditItemModel(index) {
            this.oneItem = {};
            this.oneItem = this.tableData[index];
            this.editModel = true;
        },
        openDetailsModel(index) {
            this.oneItem = {};
            this.oneItem = this.tableData[index];
            this.detailsModel = true;
        },

        chooseTree(choose) {
            console.log(choose)
            this.searchSubjectId = choose
            this.uploadData.subject_id = choose
        },
        openImportTopicModel() {
            this.importTopicModel = true
        },


        tableFormatPattern(row) {
            //模式:1顺序练习;2专项练习;3错题练习;5模拟考试;6复卷
            switch (row.pattern) {
                case 1:
                    return '顺序练习';
                case 2:
                    return '专项练习';
                case 3:
                    return '错题练习';
                case 4:
                    return '未知';
                case 5:
                    return '模拟考试';
                case 6:
                    return '复卷';
                default:
                    return '未知';
            }
        },

    }
}
</script>
