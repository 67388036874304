// xx.js文件
var service = {}

service.install = function (Vue) {
    Vue.prototype.$tools = service
    Vue.tools = service
}



service.addNum = function (x, y) {
    return x * y
} // 还可以在这个文件里面添加多个函数

service.get



export default service
