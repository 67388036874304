<template>
    <el-card>
        <div slot="header" class="main-page-title"><span>课程管理</span></div>
        <el-form :inline="true">
            <el-form-item>
                <el-button type="primary" @click="openAddItemModel()">新增</el-button>
            </el-form-item>
            <el-form-item>
                <subject-tree v-model="subject_id" @change="chooseTree"></subject-tree>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getDataList">查询</el-button>
            </el-form-item>
        </el-form>


        <br>


        <el-row>
            <el-table :data="tableData" border :cell-style="cellStyle" size="small" default-expand-all row-key="id"
                      :tree-props="{children: 'children'}">
                <el-table-column prop="name" label="课程名称"></el-table-column>
              <el-table-column prop="category" label="课程类型" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.category==2">实操视频</span>
                  <span v-else>理论学习</span>
                </template>
              </el-table-column>
                <el-table-column prop="video" label="视频地址"></el-table-column>
                <el-table-column prop="video_no" label="视频编号"></el-table-column>
                <el-table-column prop="video_duration" label="时长"></el-table-column>
                <el-table-column prop="rank" label="排序"></el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <!--<el-button @click="openAddItemModel(scope.row)" type="text" size="small">新增下一级</el-button>-->
                        <el-button @click="openEditItemModel(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="delItem(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>

        <el-dialog title="新增编辑课程" :visible.sync="editModel">
            <el-form :model="oneItem">

              <el-form-item label="课程类型">
                <el-radio-group v-model="oneItem.category">
                  <el-radio :label="1">理论学习</el-radio>
                  <el-radio :label="2">实操视频</el-radio>
                </el-radio-group>

              </el-form-item>

                <el-form-item label="课程名称">
                    <el-input v-model="oneItem.name"></el-input>
                </el-form-item>

                <el-form-item label="视频时长(秒)">
                    <el-input type="number" v-model="oneItem.video_duration"></el-input>
                </el-form-item>

                <el-form-item label="视频地址">
                    <el-input v-model="oneItem.video"></el-input>
                </el-form-item>
                <el-form-item label="视频编号">
                  <el-input v-model="oneItem.video_no"></el-input>
                </el-form-item>

              <el-form-item label="排序(值越大越靠前)">
                <el-input type="number" v-model="oneItem.rank"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editModel = false">取 消</el-button>
                <el-button type="primary" @click="editItem">确 定</el-button>
            </div>
        </el-dialog>


    </el-card>
</template>

<script>
    import SubjectTree from "@/components/subject-tree.vue"

    export default {
        data() {
            return {
                nowPage: 1, // 当前页
                pageTotal: 0, // 总页数
                pageSize: 10, // 每页数量

                tableData: [],

                subject_id: 0,
                searchValue: '', // 搜索值

                oneItem: {
                    name: '',
                    intro: '',
                    video: '',
                    video_no: '',
                    video_duration: 0,
                    pid:0,
                    subject_id: 0,
                    category: 1,
                    rank:0,
                },
                editModel: false, // 新增编辑
                detailsModel: false, // 详情弹窗
            }
        },
        created() {
            this.getDataList();
        },
        components: {
            SubjectTree
        },
        methods: {
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.getDataList()
            },
            handlePageChange(page) {
                this.nowPage = page;
                this.getDataList()
            },
            getDataList() {
                this.tableData = [];
                this.$http.get("/backend/courses/tree", {
                    params: {
                        word: this.searchValue,
                        subject_id: this.subject_id,
                    }
                }).then(res => {
                    console.log(res.data);
                    this.tableData = res.data.data;
                });
            },

            chooseTree(choose) {
                console.log(choose)
                // this.subject_id = choose
            },
            openAddItemModel(row) {
                console.log(this.subject_id)
                let pid = 0;
                if (undefined !== row) {
                    pid = row.id
                }
                this.oneItem = {
                    name: '',
                    pid: pid,

                    category: 1,
                    rank: 0,
                    video_duration: 0,
                    video_no: '',
                    intro: '',
                    video: '',
                    subject_id: this.subject_id
                };
                this.editModel = true;
            },
            openEditItemModel(row) {
                this.oneItem = {};
                this.oneItem = row;
                this.editModel = true;
            },
            openDetailsModel(index) {
                this.oneItem = {};
                this.oneItem = this.tableData[index];
                this.detailsModel = true;
            },

            editItem() {
                this.oneItem.video_duration=parseFloat(this.oneItem.video_duration);
                this.oneItem.rank=parseFloat(this.oneItem.rank);
                this.$http.post('/backend/course', this.oneItem).then(resData => {
                    if (200 === resData.data.code) {
                        this.$notify({
                            title: '成功',
                            message: '添加成功',
                            type: 'success'
                        });
                        this.editModel = false;
                        this.getDataList();
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: resData.data.msg
                        });
                    }
                })
            },
            delItem(row) {
                this.$http.delete('/backend/course/' + row.id).then(resData => {
                    if (200 === resData.data.code) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getDataList();
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: resData.data.msg
                        });
                    }
                });
            },
        }
    }
</script>
