<template>
    <div>
        <el-cascader style="width: 100%"  placeholder="请选择科目" v-model="choose" :options="treeData" @change="handleChange" clearable></el-cascader>
    </div>
</template>

<script>
    export default {
        name:"subject-tree",
        props: ['value'],
        model: {
            prop: 'value',
            event: 'input',
        },
        computed: {
            choose: {
                get() {
                    return this.value
                },
                set(val) {
                    if (val && val.length>0){
                        this.$emit('input', val[val.length-1])
                        this.$emit('change', val[val.length-1])
                    }else {
                        this.$emit('input', 0)
                        this.$emit('change', 0)
                    }
                }
            }
        },
        data() {
            return {
                treeData: [],
            }
        },
        created() {
            this.getDataTree();
        },
        methods: {
            handleChange() {

            },
            getDataTree() {
                this.treeData = [];
                this.$http.get("/common/subject/tree").then(res => {
                    console.log(res.data);
                    this.treeData = res.data.data;
                });
            },

        }
    }
</script>
