<template>
    <el-card>
        <div slot="header" class="main-page-title"><span>班级管理</span></div>
        <el-row>
            <el-button type="primary" @click="openAddItemModel()" size="small">新增</el-button>
        </el-row>
        <br>


        <el-row>
            <el-table v-loading="tableLoading" :data="tableData" border :cell-style="cellStyle" size="small" row-key="id">
                <el-table-column prop="name" label="班级名称"></el-table-column>
                <el-table-column prop="number" label="班级编号"></el-table-column>
                <el-table-column prop="subject.name" label="科目"></el-table-column>
                <el-table-column prop="organ.name" label="机构"></el-table-column>
                <el-table-column prop="teacher.name" label="班主任"></el-table-column>
                <el-table-column prop="creator.nickname" label="创建人"></el-table-column>
                <el-table-column width="250" label="时间">
                    <template slot-scope="scope">
                        <strong>开始时间:</strong><span>{{scope.row.start_time | datefmt('YYYY-MM-DD')}}</span><br>
                        <strong>结束时间:</strong><span>{{scope.row.expired_time | datefmt('YYYY-MM-DD')}}</span><br>
                        <strong>创建时间:</strong><span>{{scope.row.created_at | datefmt('YYYY-MM-DD')}}</span><br>
                    </template>
                </el-table-column>
                <el-table-column width="220" fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button @click="openDetailsModel(scope.row)" type="text" size="small">二维码</el-button>
                        <el-button @click="toUserList(scope.row)" type="text" size="small">学员管理</el-button>
                        <el-button @click="openEditItemModel(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="delItem(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>

        <el-dialog title="新增编辑班级" :visible.sync="editModel" width="80%">
            <el-form :model="oneItem" label-width="80px">
                <el-form-item label="机构">
                    <local-select v-model="oneItem.organ_id" type="organs"></local-select>
                </el-form-item>
                <el-form-item label="班主任">
                    <local-select v-model="oneItem.teacher_id" type="user_teachers"></local-select>
                </el-form-item>
                <el-form-item label="班级名称">
                    <el-input v-model="oneItem.name"></el-input>
                </el-form-item>
                <el-form-item label="验证次数">
                    <el-input-number v-model="oneItem.verify_times" :min="1" :max="4"></el-input-number>
                </el-form-item>
                <el-form-item label="班级时间">
                    <el-date-picker
                        v-model="classTimes"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="科目">
                    <subject-tree v-model="oneItem.subject_id" @change="chooseTree"></subject-tree>
                </el-form-item>
                <el-form-item label="选择课程">
                    <el-transfer :list-style="{width: '600px'}" v-model="oneItem.course_ids"
                                 :titles="['待选择', '已选择']"
                                 :button-texts="['移除', '增加']"
                                 :data="courses"></el-transfer>
                </el-form-item>



            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="editModel = false">取 消</el-button>
                <el-button type="primary" @click="editItem">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="班级二维码" :visible.sync="detailsModel" width="65%">
           <div>
               <vue-qr size="300" :correctLevel="3" :text="oneItem.qrcode" ></vue-qr>
           </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="detailsModel = false">取 消</el-button>
                <el-button type="primary" @click="detailsModel = false">确 定</el-button>
            </div>
        </el-dialog>


    </el-card>
</template>

<script>
import LocalSelect from "@/components/local-select.vue"
import SubjectTree from "@/components/subject-tree.vue";
import VueQr from 'vue-qr'
export default {
    components: {
        SubjectTree,
        LocalSelect,
        VueQr
    },
    data() {
        return {
            nowPage: 1, // 当前页
            pageTotal: 0, // 总页数
            pageSize: 10, // 每页数量

            tableData: [],
            tableLoading:true,
            searchValue: '', // 搜索值
            classTimes: [],//日期
            courses: [],
            oneItem: {
                name: '',
                verify_times: '',
                start_time: '',
                expired_time: '',
                subject_id: 0,
                organ_id: 0,
                teacher_id: 0,
                course_ids: [],
            },

            import_class_id: 0,
            import_id_cards: "",

            editModel: false, // 新增编辑
            importUserModel: false, // 新增编辑

            detailsModel: false, // 详情弹窗
        }
    },
    created() {
        this.getDataList();
    },
    methods: {
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getDataList()
        },
        handlePageChange(page) {
            this.nowPage = page;
            this.getDataList()
        },
        getDataList() {
            this.tableLoading=true
            this.tableData = [];
            this.$http.get("/backend/class", {
                params: {
                    word: this.searchValue,
                }
            }).then(res => {
                this.tableLoading=false
                this.tableData = res.data.data;
            });
        },
        getCourses() {
            this.courses = [];
            if (this.oneItem.subject_id===0){
                return
            }
            this.$http.get("/backend/courses/class", {
                params: {
                    subject_id: this.oneItem.subject_id,
                }
            }).then(res => {
                this.courses = res.data.data;
            });
        },


        openAddItemModel() {
            this.oneItem = {
                name: '',
                verify_times: '',
                start_time: '',
                expired_time: '',
                subject_id: 0,
                organ_id: 0,
                teacher_id: 0,
                course_ids: [],
            };
            this.editModel = true;
        },
        openEditItemModel(row) {
            this.oneItem = {
                name: '',
                verify_times: '',
                start_time: '',
                expired_time: '',
                subject_id: 0,
                organ_id: 0,
                teacher_id: 0,
                course_ids: [],
            };
            this.oneItem = row;
            this.getCourses();
            this.oneItem.course_ids=row.course_ids
            console.log("row===>",row)
            console.log("this.oneItem.course_ids===>",this.oneItem.course_ids)
            this.classTimes=[this.oneItem.start_time,this.oneItem.expired_time]
            this.editModel = true;
        },
        openDetailsModel(row){
            this.oneItem=row;
            this.detailsModel=true;
        },
        openImportUserModel(row) {
            this.import_class_id = row.id;
            this.import_id_cards = "";
            this.importUserModel = true;
        },
        chooseTree(choose) {
            console.log("chooseTree",choose)
            this.getCourses()
        },
        editItem() {
            if (null !== this.classTimes && 2 === this.classTimes.length) {
                this.oneItem.start_time = this.classTimes[0]
                this.oneItem.expired_time = this.classTimes[1]
            } else {
                this.$notify.error({
                    title: '错误',
                    message: "请选择时间"
                });
                return
            }

            this.$http.post('/backend/class', this.oneItem).then(resData => {
                if (200 === resData.data.code) {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.editModel = false;
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: resData.data.msg
                    });
                }
            })
        },
        delItem(row) {
            this.$http.delete('/backend/class/' + row.id).then(resData => {
                if (200 === resData.data.code) {
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: resData.data.msg
                    });
                }
            });
        },

        importUser() {
            this.$http.post('/backend/class/import_user', {
                class_id: this.import_class_id,
                id_cards: this.import_id_cards
            }).then(resData => {
                if (200 === resData.data.code) {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.importUserModel = false;
                    this.getDataList();
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: resData.data.msg
                    });
                }
            })
        },

        toUserList(row) {
            // this.$router.push('/class/students?class_id='+row.id);
            this.$router.push({
                name: "学员管理", //这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
                params: row
            });
        }


    }
}
</script>
