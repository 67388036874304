<template>
    <el-card>
        <div slot="header" class="main-page-title"><span>充值记录</span></div>

        <el-form :inline="true">
            <el-form-item label="代理机构">
                <el-select v-model="searchOrganId" clearable placeholder="代理机构">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="直属" value="0"></el-option>
                    <el-option
                            v-for="item in organs"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="支付状态">
                <el-select v-model="searchPayStatus" clearable placeholder="支付状态">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="未支付" value="0"></el-option>
                    <el-option label="已支付" value="1"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="支付时间">
                <el-date-picker
                        v-model="searchTimes"
                        type="datetimerange"
                        :picker-options="pickerOptions"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getDataList">查询</el-button>
            </el-form-item>
        </el-form>


        <br>

        <el-row>
            <el-table :data="tableData" :cell-style="cellStyle" border size="small">
                <el-table-column prop="user" label="购买人" width="200">
                    <template slot-scope="scope">
                        {{ scope.row.user.nickname }}(ID:{{ scope.row.user.id }})
                        <br>
                        <span v-if="scope.row.organ.id>0">(代理:{{ scope.row.organ.name }})</span>
                    </template>
                </el-table-column>
                <el-table-column prop="product.title" label="购买商品" width="125"></el-table-column>
                <el-table-column prop="subject" label="充值科目">
                    <template slot-scope="scope">
                        <div v-if="scope.row.subject.id>0">{{ scope.row.subject.name }}(ID:{{ scope.row.subject.id }})
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="pay_amount" label="金额" width="50"></el-table-column>
                <el-table-column prop="pay_no" label="微信订单号"></el-table-column>
                <el-table-column prop="time" label="时间" width="200">
                    <template slot-scope="scope">
                        下单时间:{{ scope.row.created_at }}<br>
                        支付时间:{{ scope.row.pay_time }}
                    </template>
                </el-table-column>
            </el-table>
        </el-row>


        <el-row>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <el-pagination
                            @size-change="handlePageSizeChange"
                            @current-change="handlePageChange"
                            :current-page="nowPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal">
                    </el-pagination>
                </div>
            </div>
        </el-row>

    </el-card>
</template>

<script>
// import LocalSelect from "@/components/local-select.vue"

import SubjectTree from "@/components/subject-tree.vue";

export default {
    components: {
        SubjectTree
        // LocalSelect,
    },
    data() {
        return {
            nowPage: 1, // 当前页
            pageTotal: 0, // 总页数
            pageSize: 10, // 每页数量

            tableData: [],
            organs: [],

            searchValue: '', // 搜索值
            searchOrganId: '', // 代理机构id
            searchPayStatus: '',//支付状态
            searchTimes: [],//搜索日期

            oneItem: {},
            editModel: false, // 新增编辑
            detailsModel: false, // 详情弹窗

            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },

    created() {
        this.getOrgans();
        this.getDataList();
    },
    methods: {
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getDataList()
        },
        handlePageChange(page) {
            this.nowPage = page;
            this.getDataList()
        },
        getOrgans() {
            this.$http.get("/backend/organs").then(res => {
                this.organs = res.data.data;
            });
        },
        getDataList() {
            this.tableData = [];
            var start_at = "";
            var end_at = "";
            if (null !== this.searchTimes && 2 === this.searchTimes.length) {
                start_at = this.searchTimes[0]
                end_at = this.searchTimes[1]
            }
            this.$http.get("/backend/orders", {
                params: {
                    page: this.nowPage,
                    pre_page: this.pageSize,
                    word: this.searchValue,
                    organ_id: this.searchOrganId,
                    status: this.searchPayStatus,
                    start_at: start_at,
                    end_at: end_at,
                }
            }).then(res => {
                console.log(res.data);
                this.tableData = res.data.data;
                this.pageTotal = res.data.meta.total;
            });
        },


    }
}
</script>
