<template>
  <el-card>
    <div slot="header" class="main-page-title"><span>登记记录</span></div>
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="toExport">导出</el-button>
      </el-form-item>
      <el-form-item label="报名时间">
        <el-date-picker
            v-model="searchTimes"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchName" size="small" placeholder="请输入姓名搜索"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchIdCard" size="small" placeholder="请输入身份证号搜索"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getDataList">查询</el-button>
      </el-form-item>
    </el-form>


    <br>

    <el-row>
      <el-table :data="tableData" :cell-style="cellStyle" border size="small">
        <el-table-column prop="name" label="姓名" width="70"></el-table-column>
        <el-table-column prop="id_card" label="身份证"></el-table-column>
        <el-table-column prop="created_at" label="登记时间" width="150"></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="download(scope.row)" type="text" size="small">导出资料</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>


    <el-row>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
          <el-pagination
              @size-change="handlePageSizeChange"
              @current-change="handlePageChange"
              :current-page="nowPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal">
          </el-pagination>
        </div>
      </div>
    </el-row>

  </el-card>
</template>

<script>
// import JSZip from 'jszip'
// import FileSaver from 'file-saver'
import {saveAs} from 'file-saver';

export default {
  data() {
    return {
      nowPage: 1, // 当前页
      pageTotal: 0, // 总页数
      pageSize: 10, // 每页数量

      tableData: [],
      organs: [],

      searchName: '', // 搜索值
      searchIdCard: '', // 搜索值
      searchOrganId: '', // 代理机构id
      searchPayStatus: '',//支付状态
      searchTimes: [],//搜索日期

      oneItem: {},
      editModel: false, // 新增编辑
      detailsModel: false, // 详情弹窗

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },

  created() {
    this.getOrgans();
    this.getDataList();
  },
  methods: {
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.getDataList()
    },
    handlePageChange(page) {
      this.nowPage = page;
      this.getDataList()
    },
    getOrgans() {
      this.$http.get("/backend/organs").then(res => {
        this.organs = res.data.data;
      });
    },
    getDataList() {
      this.tableData = [];
      var start_at = "";
      var end_at = "";
      if (null !== this.searchTimes && 2 === this.searchTimes.length) {
        start_at = this.searchTimes[0]
        end_at = this.searchTimes[1]
      }
      this.$http.get("/backend/registers", {
        params: {
          page: this.nowPage,
          pre_page: this.pageSize,
          name: this.searchName,
          id_card: this.searchIdCard,
          organ_id: this.searchOrganId,
          status: this.searchPayStatus,
          screen: "info",
          start_at: start_at,
          end_at: end_at,
        }
      }).then(res => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.meta.total;
      });
    },

    toExport() {
      var start_at = "";
      var end_at = "";
      if (null !== this.searchTimes && 2 === this.searchTimes.length) {
        start_at = this.searchTimes[0]
        end_at = this.searchTimes[1]
      }
      var export_filename = "登记列表-" + Date.parse(new Date()) + ".xlsx"
      const xhr = new XMLHttpRequest();
      xhr.open('get', "/api/backend/registers?export=1&screen=info&start_time=" + start_at + "&end_time=" + end_at);
      xhr.setRequestHeader("Authorization", 'Bearer ' + window.localStorage.getItem('token'));
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = function (res) {
        console.log(res)
        if (this.status === 200 || this.status === 304) {
          // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
          if ('msSaveOrOpenBlob' in navigator) {
            navigator.msSaveOrOpenBlob(this.response, export_filename);
            return;
          }
          // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
          // const url = URL.createObjectURL(blob);
          const url = URL.createObjectURL(this.response);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = export_filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
      };
    },
    downloadWord(name, row) {
      var export_filename = name + "-" + row.name + "-" + Date.parse(new Date()) + ".docx"
      const xhr = new XMLHttpRequest();
      xhr.open('get', "/api/backend/register/" + row.id + "/generate_word/" + name);
      xhr.setRequestHeader("Authorization", 'Bearer ' + window.localStorage.getItem('token'));
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = function (res) {
        console.log(res)
        if (this.status === 200 || this.status === 304) {
          // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
          if ('msSaveOrOpenBlob' in navigator) {
            navigator.msSaveOrOpenBlob(this.response, export_filename);
            return;
          }
          // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
          // const url = URL.createObjectURL(blob);
          const url = URL.createObjectURL(this.response);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = export_filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
      };
    },

    download(row) {
      let images = []
      let id_card = row.id_card??"";
      if (row.id_card_avatar.length > 0) {
        images.push({
          url: row.id_card_avatar,
          // fileName: "证件照",
          fileName: id_card+"-4",
        })
      }
      if (row.id_card_front.length > 0) {
        images.push({
          url: row.id_card_front,
          // fileName: "身份证正面",
          fileName: id_card+"-1",
        })
      }
      if (row.id_card_back.length > 0) {
        images.push({
          url: row.id_card_back,
          fileName: id_card+"-2",
        })
      }
      if (row.education_materials.length > 0) {
        images.push({
          url: row.education_materials,
          // fileName: "学历材料",
          fileName: id_card+"-3",
        })
      }
      console.log(row, images)
      if (images.length > 0) {
        this.wrapImageToZip(images, row.name + "-学员资料")
      } else {
        this.$notify.error({
          title: '错误',
          message: "该学员没有上传相关材料"
        });
      }
    },


    getBase64Image(image, ext) {
      let canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, image.width, image.height);
      // 这里是不支持跨域的
      let base64 = canvas.toDataURL(`image/${ext}`);
      base64 = base64.substring(base64.indexOf(',') + 1);
      return base64;
    },

    wrapImageToZip(urlList, filename) {
      let imageCount = urlList.length,
          numCount = 0,
          arrBase64 = [];

      urlList.map(item => {
        let url = `${item.url}?a=${new Date().getTime()}`, image = new Image();
        image.src = url;
        image.setAttribute('crossOrigin', '*');
        image.onload = () => {
          numCount++;
          let ext = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'))
          let base64 = this.getBase64Image(image, ext); //需要先将图片转换为base64
          let itemName = item.fileName
          arrBase64.push({ext, base64,itemName});
          if (numCount === imageCount) {
            Promise.all([require('jszip'), import('file-saver')]).then(([JSZip, FileSaver]) => {
              let zip = new JSZip();
              let img = zip.folder('');
              for (let bItem of arrBase64.entries()) {
                // img.file(`${bItem[1].itemName}-${bItem[0]}.${bItem[1].ext}`, bItem[1].base64, {base64: true});
                img.file(`${bItem[1].itemName}.${bItem[1].ext}`, bItem[1].base64, {base64: true});
              }
              // 图片是base64格式,但不要base64前缀
              let fileName = `${filename}.zip`;
              zip.generateAsync({type: 'blob'}).then((content) => {
                saveAs(content, fileName);
              });
            });
          }
        };
      });
    }

  }
}
</script>
